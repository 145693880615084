import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticStyle:{"max-width":"80vw!important","margin-left":"0"}},[_c('p',[_c('b',[_vm._v("Cobertura")])]),_c(VDataTable,{staticClass:"w-auto",attrs:{"item-key":"seqipo","dense":"","headers":_vm.headersItens,"items":_vm.coberturaItens,"items-per-page":10,"mobile-breakpoint":0},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c(VProgressLinear,{attrs:{"absolute":"","indeterminate":"","color":"green"}})]},proxy:true},{key:"item.total_aplicado",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"num_margin"},[_vm._v(_vm._s(_vm.$stringFormat.formatNumber(item.total_aplicado, 2)))])]}},{key:"item.dosagem",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"num_margin"},[_vm._v(_vm._s(_vm.$stringFormat.formatNumber(item.dosagem, 2)))])]}}],null,true)}),_c('hr'),_c('p',[_c('b',[_vm._v("Fósforo")])]),_c(VDataTable,{staticClass:"w-auto",attrs:{"item-key":"seqipo","dense":"","headers":_vm.headersItens,"items":_vm.fosforoItens,"items-per-page":10,"mobile-breakpoint":0},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c(VProgressLinear,{attrs:{"absolute":"","indeterminate":"","color":"green"}})]},proxy:true},{key:"item.total_aplicado",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"num_margin"},[_vm._v(_vm._s(_vm.$stringFormat.formatNumber(item.total_aplicado, 2)))])]}},{key:"item.dosagem",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"num_margin"},[_vm._v(_vm._s(_vm.$stringFormat.formatNumber(item.dosagem, 2)))])]}}],null,true)}),_c('hr'),_c('p',[_c('b',[_vm._v("Corretivo")])]),_c(VDataTable,{staticClass:"w-auto",attrs:{"item-key":"seqipo","dense":"","headers":_vm.headersItens,"items":_vm.corretivoItens,"items-per-page":10,"mobile-breakpoint":0},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c(VProgressLinear,{attrs:{"absolute":"","indeterminate":"","color":"green"}})]},proxy:true},{key:"item.total_aplicado",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"num_margin"},[_vm._v(_vm._s(_vm.$stringFormat.formatNumber(item.total_aplicado, 2)))])]}},{key:"item.dosagem",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"num_margin"},[_vm._v(_vm._s(_vm.$stringFormat.formatNumber(item.dosagem, 2)))])]}}],null,true)}),_c('hr'),_c('p',[_c('b',[_vm._v("Outras aplicações")])]),_c(VDataTable,{staticClass:"w-auto",attrs:{"item-key":"seqipo","dense":"","headers":_vm.headersItens,"items":_vm.demaisItens,"items-per-page":10,"mobile-breakpoint":0},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c(VProgressLinear,{attrs:{"absolute":"","indeterminate":"","color":"green"}})]},proxy:true},{key:"item.total_aplicado",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"num_margin"},[_vm._v(_vm._s(_vm.$stringFormat.formatNumber(item.total_aplicado, 2)))])]}},{key:"item.dosagem",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"num_margin"},[_vm._v(_vm._s(_vm.$stringFormat.formatNumber(item.dosagem, 2)))])]}},{key:"item.data_realizacao",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"num_margin"},[_vm._v(_vm._s(_vm.$date.validDate(item.data_realizacao)))])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }