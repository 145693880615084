import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VToolbar,{staticClass:"d-flex flex-row-reverse pb-5",attrs:{"flat":"","color":"rgba(0,0,0,0)"}},[(_vm.permiteManipularConfiguracoes)?_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-width":400},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mr-3",attrs:{"color":"primary","dark":"","title":"Configuracoes"}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-cog")])],1)]}}],null,false,83231167)},[_c(VCard,{staticClass:"pt-4"},[_c(VCol,{staticClass:"pb-0 col-12"},[_c('ProdutoSelect',{attrs:{"nome":"cobertura","empresa":_vm.filtro.empresaId,"producao":_vm.filtro.producaoId,"insumos":"","label":"Cobertura","multiple":""},on:{"change":function($event){return _vm.atualizaConfiguracoes(1, $event)}},model:{value:(_vm.cobertura),callback:function ($$v) {_vm.cobertura=$$v},expression:"cobertura"}})],1),_c(VCol,{staticClass:"pb-0 col-12"},[_c('ProdutoSelect',{attrs:{"nome":"fosforo","empresa":_vm.filtro.empresaId,"producao":_vm.filtro.producaoId,"insumos":"","label":"Fósforo","multiple":""},on:{"change":function($event){return _vm.atualizaConfiguracoes(2, $event)}},model:{value:(_vm.fosforo),callback:function ($$v) {_vm.fosforo=$$v},expression:"fosforo"}})],1),_c(VCol,{staticClass:"pb-0 col-12"},[_c('ProdutoSelect',{attrs:{"nome":"corretivo","empresa":_vm.filtro.empresaId,"producao":_vm.filtro.producaoId,"insumos":"","label":"Corretivo","multiple":""},on:{"change":function($event){return _vm.atualizaConfiguracoes(3, $event)}},model:{value:(_vm.corretivo),callback:function ($$v) {_vm.corretivo=$$v},expression:"corretivo"}})],1)],1)],1):_vm._e(),_c(VBtn,{staticClass:"mr-3",attrs:{"color":"primary","dark":"","title":"Remover Filtros"},on:{"click":_vm.removeFiltros}},[_c(VIcon,[_vm._v("mdi-filter-remove")])],1),_c('HeaderControl',{attrs:{"headers":_vm.headersOptions,"local-storage-save-name":_vm.localStorageSaveName},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_c('Exportar',{attrs:{"filtro":_vm.filtro,"disabled":!_vm.filtro.empresaId || !_vm.filtro.producaoId,"exports":_vm.exports}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }