<template>
  <v-container style="max-width: 80vw!important; margin-left: 0">
    <p><b>Cobertura</b></p>
    <v-data-table
        class="w-auto"
        item-key="seqipo"
        dense
        :headers="headersItens"
        :items="coberturaItens"
        :items-per-page="10"
        :mobile-breakpoint="0"
    >

      <template v-slot:progress>
        <v-progress-linear
            absolute
            indeterminate
            color="green"
        />
      </template>

      <template v-slot:[`item.total_aplicado`]="{ item }">
        <span class="num_margin">{{ $stringFormat.formatNumber(item.total_aplicado, 2) }}</span>
      </template>

      <template v-slot:[`item.dosagem`]="{ item }">
        <span class="num_margin">{{ $stringFormat.formatNumber(item.dosagem, 2) }}</span>
      </template>
    </v-data-table>
    <hr>
    <p><b>Fósforo</b></p>
    <v-data-table
        class="w-auto"
        item-key="seqipo"
        dense
        :headers="headersItens"
        :items="fosforoItens"
        :items-per-page="10"
        :mobile-breakpoint="0"
    >

      <template v-slot:progress>
        <v-progress-linear
            absolute
            indeterminate
            color="green"
        />
      </template>

      <template v-slot:[`item.total_aplicado`]="{ item }">
        <span class="num_margin">{{ $stringFormat.formatNumber(item.total_aplicado, 2) }}</span>
      </template>

      <template v-slot:[`item.dosagem`]="{ item }">
        <span class="num_margin">{{ $stringFormat.formatNumber(item.dosagem, 2) }}</span>
      </template>
    </v-data-table>
    <hr>
    <p><b>Corretivo</b></p>
    <v-data-table
        class="w-auto"
        item-key="seqipo"
        dense
        :headers="headersItens"
        :items="corretivoItens"
        :items-per-page="10"
        :mobile-breakpoint="0"
    >

      <template v-slot:progress>
        <v-progress-linear
            absolute
            indeterminate
            color="green"
        />
      </template>

      <template v-slot:[`item.total_aplicado`]="{ item }">
        <span class="num_margin">{{ $stringFormat.formatNumber(item.total_aplicado, 2) }}</span>
      </template>

      <template v-slot:[`item.dosagem`]="{ item }">
        <span class="num_margin">{{ $stringFormat.formatNumber(item.dosagem, 2) }}</span>
      </template>
    </v-data-table>
    <hr>
    <p><b>Outras aplicações</b></p>
    <v-data-table
        class="w-auto"
        item-key="seqipo"
        dense
        :headers="headersItens"
        :items="demaisItens"
        :items-per-page="10"
        :mobile-breakpoint="0"
    >

      <template v-slot:progress>
        <v-progress-linear
            absolute
            indeterminate
            color="green"
        />
      </template>

      <template v-slot:[`item.total_aplicado`]="{ item }">
        <span class="num_margin">{{ $stringFormat.formatNumber(item.total_aplicado, 2) }}</span>
      </template>

      <template v-slot:[`item.dosagem`]="{ item }">
        <span class="num_margin">{{ $stringFormat.formatNumber(item.dosagem, 2) }}</span>
      </template>
      <template v-slot:[`item.data_realizacao`]="{ item }">
        <span class="num_margin">{{ $date.validDate(item.data_realizacao) }}</span>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>

export default {
  props: {
    item: {
      type: Object,
    },
    configuracoes: {
      type: Object,
    },
  },

  computed: {
    headersItens () {
      return [
        { text: 'Código Produto', value: 'produto_id' },
        { text: 'Produto', value: 'produto' },
        { text: 'Data Aplicação', value: 'data_realizacao', align: 'right' },
        { text: 'Unidade Medida', value: 'unidade_medida', align: 'right' },
        { text: 'Quantidade', value: 'total_aplicado', align: 'right' },
        { text: 'Por Hectare', value: 'dosagem', align: 'right' },
      ]
    },
    coberturaItens () {
      return this.item.produtos.filter((produto) => this.configuracoes.cobertura.indexOf(produto.produto_id) >= 0)
        .sort((a, b) => Date.parse(a.data_realizacao) - Date.parse(b.data_realizacao))
    },
    fosforoItens () {
      return this.item.produtos.filter((produto) => this.configuracoes.fosforo.indexOf(produto.produto_id) >= 0)
        .sort((a, b) => Date.parse(a.data_realizacao) - Date.parse(b.data_realizacao))
    },
    corretivoItens () {
      return this.item.produtos.filter((produto) => this.configuracoes.corretivo.indexOf(produto.produto_id) >= 0)
        .sort((a, b) => Date.parse(a.data_realizacao) - Date.parse(b.data_realizacao))
    },
    demaisItens () {
      return this.item.produtos.filter((produto) => {
        return this.configuracoes.cobertura.indexOf(produto.produto_id) < 0 &&
            this.configuracoes.fosforo.indexOf(produto.produto_id) < 0 &&
            this.configuracoes.corretivo.indexOf(produto.produto_id) < 0
      })
    },
  },
  methods: {
    converteUnidadeMedida (unidadeMedida, valor) {
      if (unidadeMedida === 'TN') {
        return parseFloat(valor) * 1000
      }

      return parseFloat(valor)
    },
  },
}
</script>
