<template>
  <div>
    <custom-snackbar ref="snackbar" />
    <ActionsComponent
        v-model="headers"
        @changeConfiguracoes="configuracoes = $event"
        :filtro="filtros"
        :headers-options="headersOptions"
        local-storage-save-name="relatorio-aplicacao-produto"/>
    <FilterComponent v-model="filtros" />
    <ListComponents :filtros="filtros" :headers="headers" :configuracoes="configuracoes"/>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

import ActionsComponent from './components/ActionsComponent'
import FilterComponent from './components/FilterComponent'
import ListComponents from './components/ListComponents'

export default {
  components: { ActionsComponent, FilterComponent, ListComponents },
  data: (instance) => {
    return {
      configuracoes: {
        cobertura: [],
        fosforo: [],
        corretivo: [],
      },
      filtros: {
        empresaId: instance.filtroValue(instance.$route.query.empresaId, '', 'int'),
        producaoId: instance.filtroValue(instance.$route.query.producaoId, '', 'int'),
        talhaoId: instance.filtroValue(instance.$route.query.talhaoId, '', 'int'),
        ciclos: instance.filtroValue(instance.$route.query.ciclos, 3, 'int'),
        produtos: instance.filtroValue(instance.$route.query.produtos, [], 'array'),
      },
      headers: [],
      headersOptions: [
        { value: 'data-table-expand', align: 'right p-0', class: 'sticky-header custom-table--header', total: 'title' },
        { text: 'Talhão', value: 'talhao', class: 'sticky-header custom-table--header', obrigatorio: true },
        { text: 'Ciclo', value: 'ciclo', class: 'sticky-header custom-table--header', obrigatorio: true },

        { text: 'Data Abertura', value: 'data_abertura', class: 'sticky-header custom-table--header', ativado: true },
        { text: 'Data Fechamento', value: 'data_fechamento', class: 'sticky-header custom-table--header', ativado: true },

        { text: 'Area (m²)', value: 'area', align: 'right', class: 'sticky-header custom-table--header', ativado: true, total: 'sum', totalFormat: (total) => instance.$stringFormat.formatNumber(total, 0) },
        { text: 'Colhido (m²)', value: 'area_colhida', align: 'right', class: 'sticky-header custom-table--header', ativado: true, total: 'sum', totalFormat: (total) => instance.$stringFormat.formatNumber(total, 0) },
        { text: 'Acerto (m²)', value: 'area_acerto', align: 'right', class: 'sticky-header custom-table--header', ativado: true, total: 'sum', totalFormat: (total) => instance.$stringFormat.formatNumber(total, 0) },
        { text: 'Movimentado (m²)', value: 'area_movimentado', align: 'right', class: 'sticky-header custom-table--header', ativado: true, total: 'sum', totalFormat: (total) => instance.$stringFormat.formatNumber(total, 0) },
        { text: 'Produtividade', value: 'produtividade', align: 'right', class: 'sticky-header custom-table--header', ativado: true, total: 'avg', totalFormat: (total) => instance.$stringFormat.formatNumber(total, 2) + '%' },

        { text: 'Cobertura', value: 'cobertura', align: 'right', class: 'sticky-header custom-table--header', ativado: true, total: 'sum', totalFormat: (total) => instance.$stringFormat.formatNumber(total, 0) },
        { text: 'Kg/ha (Cobertura)', value: 'cobertura_aplicacao', align: 'right', class: 'sticky-header custom-table--header', ativado: true, total: 'sum', totalFormat: (total) => instance.$stringFormat.formatNumber(total, 0) },

        { text: 'Fósforo', value: 'fosforo', align: 'right', class: 'sticky-header custom-table--header', ativado: true, total: 'sum', totalFormat: (total) => instance.$stringFormat.formatNumber(total, 0) },
        { text: 'Kg/ha (Fósforo)', value: 'fosforo_aplicacao', align: 'right', class: 'sticky-header custom-table--header', ativado: true, total: 'sum', totalFormat: (total) => instance.$stringFormat.formatNumber(total, 0) },

        { text: 'Corretivo', value: 'corretivo', align: 'right', class: 'sticky-header custom-table--header', ativado: true, total: 'sum', totalFormat: (total) => instance.$stringFormat.formatNumber(total, 0) },
        { text: 'Kg/ha (Corretivo)', value: 'corretivo_aplicacao', align: 'right', class: 'sticky-header custom-table--header', ativado: true, total: 'sum', totalFormat: (total) => instance.$stringFormat.formatNumber(total, 0) },

        { text: 'Produtos', value: 'produtos_quantidade', align: 'right', class: 'sticky-header custom-table--header', ativado: true, total: 'sum', totalFormat: (total) => instance.$stringFormat.formatNumber(total, 0) },
        { text: 'Kg/ha (Produtos)', value: 'produtos_aplicacao', align: 'right', class: 'sticky-header custom-table--header', ativado: true, total: 'sum', totalFormat: (total) => instance.$stringFormat.formatNumber(total, 0) },
      ],
    }
  },
  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Agrícola', route: 'agricola' },
      { title: 'Relatório de Aplicação de Produto por Ciclo' },
    ])
  },
  methods: {
    filtroValue (value, valorPadrao, type) {
      if (value === undefined || value === null || value === '') {
        return valorPadrao
      }

      if (type === 'int') {
        return parseInt(value)
      }

      if (type === 'float') {
        return parseFloat(value)
      }

      if (type === 'array') {
        return [].concat(value)
      }

      return value
    },
  },
}
</script>
