<template>
  <div>
    <v-form ref="form">
      <FiltroMobile :has-change="change" @search="seachFilter">
        <v-col class="pb-0 col-12 col-md-6 col-lg-4">
          <EmpresasSelect obrigatorio v-model="empresaId" @change="change = true"></EmpresasSelect>
        </v-col>
        <v-col class="pb-0 col-12 col-md-6 col-lg-4">
          <ProducoesSelect obrigatorio v-model="producaoId" :empresa="empresaId" :disabled="!empresaId" @change="change = true"></ProducoesSelect>
        </v-col>
        <v-col class="pb-0 col-12 col-md-6 col-lg-4">
          <TalhoesSelect v-model="talhaoId" :empresa="empresaId" :producao="producaoId" :disabled="!producaoId" @change="change = true" mostra-todos-label></TalhoesSelect>
        </v-col>
        <v-col class="pb-0 col-12 col-md-6 col-lg-2">
          <bloco-informativo texto-informativo="Quantidade de ciclos exibidos do mais recente pro mais antigo, informe 0 para todos">
            <v-text-field
                dense
                outlined
                label="Numero Ciclos"
                type="number"
                min="0"
                v-model="ciclos"
                @change="change = true"
            />
          </bloco-informativo>
        </v-col>
        <v-col class="pb-0 col-12 col-md-8">
          <ProdutoSelect nome="produtos" v-model="produtos" :empresa="empresaId" :producao="producaoId" :disabled="!producaoId" insumos label="Produtos Expecificos" multiple></ProdutoSelect>
        </v-col>
      </FiltroMobile>
    </v-form>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: Object,
    },
  },
  data: () => {
    return {
      change: false,
      empresaId: '',
      producaoId: '',
      talhaoId: '',
      ciclos: 3,
      produtos: [],
    }
  },
  mounted () {
    this.empresaId = this.value?.empresaId
    this.producaoId = this.value?.producaoId
    this.talhaoId = this.value?.talhaoId
    this.ciclos = parseInt(this.value?.ciclos) ?? 3
    this.produtos = this.value?.produtos
  },
  watch: {
    value (val) {
      this.empresaId = val?.empresaId
      this.producaoId = val?.producaoId
      this.talhaoId = val?.talhaoId
      this.ciclos = parseInt(val?.ciclos) ?? 3
      this.produtos = val?.produtos
    },
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
  },
  methods: {
    seachFilter () {
      if (!this.$refs.form.validate()) {
        return false
      }

      this.model = {
        empresaId: this.empresaId,
        producaoId: this.producaoId,
        talhaoId: this.talhaoId,
        ciclos: this.ciclos,
        produtos: this.produtos,
      }

      this.change = false
    },
  },
}
</script>

<style scoped>

</style>
