<template>
  <v-card class="mb-5">
    <v-card-text class="py-0 px-0 px-md-4 py-md-4">
      <v-data-table
            ref="tabelaCompras"
            dense
            show-expand
            single-expand
            item-key="row-key"
            @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
            :expanded.sync="expanded"
            :headers="headers"
            :items="data"
            :loading="loading"
            :no-data-text="noData"
            :items-per-page="$dataTableConfig.getItemsPerPageCustom()"
            :footer-props="$dataTableConfig.getFooterProps()"
            :loading-text="$dataTableConfig.getLoadText()"
            :mobile-breakpoint="0"
          >
            <template v-slot:[`body.append`]="{ items }">
              <tr>
                <th v-for="header in headers" :class="header.class" :style="{ 'text-align': header.align !== 'right'? 'left' : 'right' }">
                  <span v-if="header.total === 'title'">Total</span>
                  <span v-else-if="header.total === 'sum'" class="num_margin">{{ typeof header.totalFormat === 'function'? header.totalFormat(data.reduce((sum, item) => item[header.value] + sum, 0)): data.reduce((sum, item) => item[header.value] + sum, 0) }}</span>
                  <span v-else-if="header.total === 'count'" class="num_margin">{{ typeof header.totalFormat === 'function'? header.totalFormat(data.length): data.length }}</span>
                  <span v-else-if="header.total === 'avg'" class="num_margin">{{ data.length > 0 ? (typeof header.totalFormat === 'function'? header.totalFormat(data.reduce((sum, item) => item[header.value] + sum, 0) / data.length): data.reduce((sum, item) => item[header.value] + sum, 0) / data.length) : 0 }}</span>
                  <span v-else>&nbsp;</span>
                </th>
              </tr>
            </template>
            <template v-slot:progress>
              <v-progress-linear
                absolute
                indeterminate
                color="green"
              />
            </template>

            <template v-slot:[`item.data_abertura`]="{ item }">
              <span>{{ validDate(item.data_abertura) }}</span>
            </template>

            <template v-slot:[`item.data_fechamento`]="{ item }">
              <span>{{ validDate(item.data_fechamento) }}</span>
            </template>

            <template v-slot:[`item.area`]="{ item }">
              <span class="num_margin">{{ $stringFormat.formatNumber(item.area, 0) }}</span>
            </template>

            <template v-slot:[`item.area_colhida`]="{ item }">
              <span class="num_margin">{{ $stringFormat.formatNumber(item.area_colhida, 0) }}</span>
            </template>

            <template v-slot:[`item.area_acerto`]="{ item }">
              <span class="num_margin">{{ $stringFormat.formatNumber(item.area_acerto, 0) }}</span>
            </template>

            <template v-slot:[`item.area_movimentado`]="{ item }">
              <span class="num_margin">{{ $stringFormat.formatNumber(item.area_movimentado, 0) }}</span>
            </template>

            <template v-slot:[`item.produtividade`]="{ item }">
              <span class="num_margin">{{ $stringFormat.formatNumber(item.produtividade, 2) }}%</span>
            </template>

            <template v-slot:[`item.cobertura`]="{ item }">
              <span class="num_margin">{{ $stringFormat.formatNumber(item.cobertura, 2) }}</span>
            </template>

            <template v-slot:[`item.cobertura_aplicacao`]="{ item }">
              <span class="num_margin">{{ $stringFormat.formatNumber(item.cobertura_aplicacao, 2) }}</span>
            </template>

            <template v-slot:[`item.fosforo`]="{ item }">
              <span class="num_margin">{{ $stringFormat.formatNumber(item.fosforo, 2) }}</span>
            </template>

            <template v-slot:[`item.fosforo_aplicacao`]="{ item }">
              <span class="num_margin">{{ $stringFormat.formatNumber(item.fosforo_aplicacao, 2) }}</span>
            </template>

            <template v-slot:[`item.corretivo`]="{ item }">
              <span class="num_margin">{{ $stringFormat.formatNumber(item.corretivo, 2) }}</span>
            </template>

            <template v-slot:[`item.corretivo_aplicacao`]="{ item }">
              <span class="num_margin">{{ $stringFormat.formatNumber(item.corretivo_aplicacao, 2) }}</span>
            </template>

            <template v-slot:[`item.produtos_quantidade`]="{ item }">
              <span class="num_margin">{{ $stringFormat.formatNumber(item.produtos_quantidade, 2) }}</span>
            </template>

            <template v-slot:[`item.produtos_aplicacao`]="{ item }">
              <span class="num_margin">{{ $stringFormat.formatNumber(item.produtos_aplicacao, 2) }}</span>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <ViewComponents :item="item" :configuracoes="configuracoes"></ViewComponents>
              </td>
            </template>
          </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>

import ApiService from '@/core/services/api.service'

import ViewComponents from './ViewComponents'

export default {
  components: {
    ViewComponents,
  },
  props: {
    headers: {
      type: Object,
    },
    filtros: {
      type: Object,
    },
    configuracoes: {
      type: Object,
    },
  },
  data: () => ({
    // General
    loading: false,
    noData: 'Nenhuma informação encontrada',

    // Form Data
    data: [],
    // Expand Data Table
    expanded: [],
  }),

  watch: {
    filtros () {
      this.$router.push({ query: this.filtros, silent: true }).catch((e) => e)
      this.getData()
    },
  },

  mounted () {
    this.getData()

    this.$refs.tabelaCompras.$el.querySelector('thead').addEventListener('wheel', (evt) => {
      evt.preventDefault()
      this.$refs.tabelaCompras.$el.querySelector('.v-data-table__wrapper').scrollLeft += evt.deltaY
    })
  },

  methods: {
    getData () {
      if (this.loading) {
        return
      }

      if (!this.filtros.empresaId || !this.filtros.producaoId) {
        return
      }

      this.loading = true
      this.expanded = []

      ApiService
        .get(
          '/relatorio/aplicacao-produto',
          '?' + this.$stringFormat.queryParamsRecursive(this.filtros),
        )
        .then((res) => {
          this.data = res.data.data.map((item, index) => {
            return {
              'row-key': `${index}`,
              cobertura: item.produtos
                .filter((produto) => this.configuracoes.cobertura.indexOf(produto.produto_id) >= 0)
                .reduce((sum, produto) => this.converteUnidadeMedida(produto.unidade_medida, produto.total_aplicado) + sum, 0),
              cobertura_aplicacao: item.produtos
                .filter((produto) => this.configuracoes.cobertura.indexOf(produto.produto_id) >= 0)
                .reduce((sum, produto) => this.converteUnidadeMedida(produto.unidade_medida, produto.dosagem) + sum, 0),
              fosforo: item.produtos
                .filter((produto) => this.configuracoes.fosforo.indexOf(produto.produto_id) >= 0)
                .reduce((sum, produto) => this.converteUnidadeMedida(produto.unidade_medida, produto.total_aplicado) + sum, 0),
              fosforo_aplicacao: item.produtos
                .filter((produto) => this.configuracoes.fosforo.indexOf(produto.produto_id) >= 0)
                .reduce((sum, produto) => this.converteUnidadeMedida(produto.unidade_medida, produto.dosagem) + sum, 0),
              corretivo: item.produtos
                .filter((produto) => this.configuracoes.corretivo.indexOf(produto.produto_id) >= 0)
                .reduce((sum, produto) => this.converteUnidadeMedida(produto.unidade_medida, produto.total_aplicado) + sum, 0),
              corretivo_aplicacao: item.produtos
                .filter((produto) => this.configuracoes.corretivo.indexOf(produto.produto_id) >= 0)
                .reduce((sum, produto) => this.converteUnidadeMedida(produto.unidade_medida, produto.dosagem) + sum, 0),
              produtos_quantidade: item.produtos
                .filter((produto) => this.filtros.produtos.indexOf(produto.produto_id) >= 0)
                .reduce((sum, produto) => this.converteUnidadeMedida(produto.unidade_medida, produto.total_aplicado) + sum, 0),
              produtos_aplicacao: item.produtos
                .filter((produto) => this.filtros.produtos.indexOf(produto.produto_id) >= 0)
                .reduce((sum, produto) => this.converteUnidadeMedida(produto.unidade_medida, produto.dosagem) + sum, 0),
              ...item,
            }
          })

          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    converteUnidadeMedida (unidadeMedida, valor) {
      if (unidadeMedida === 'TN') {
        return parseFloat(valor) * 1000
      }

      return parseFloat(valor)
    },
    validDate (value) {
      if (value == null) {
        return '-'
      }

      return value
    },
  },
}
</script>

<style>
.v-btn--height-fix {
  width: 100%;
  height: 40px;
}
.theme--light.v-icon:focus::after {
  opacity: 0 !important;
}
.v-btn--height-fix {
  width: 100%;
  height: 40px;
}
.theme--light.v-icon:focus::after {
  opacity: 0 !important;
}
.bg-purple {
  background-color: purple;
  color: #fff;
}
.bg-cotando {
  background-color: #1F3853;
  color: #fff;
}
.bg-pendente-cotacao {
  background-color: #3F6883;
  color: #fff;
}
.bg-pendente {
  background-color: #FDD01C;
  color: #000;
}
.bg-urgente {
  background-color: #E36968;
  color: #fff;
}
.bg-aprovada {
  background-color: #0AA699;
  color: #fff;
}
.bg-cotada {
  background-color: #0090D9;
  color: #fff;
}
.bg-comprada {
  background-color: #D1DADE;
  color: #000;
}
.bg-entregue {
  background-color: transparent;
  color: #000;
  border: 1px solid #000;
}
.label.label-lg.label-inline {
  width: 100%;
}
.custom-switch,
.custom-switch .v-input__slot,
.custom-switch label {
  padding: 0 !important;
  margin: 0 !important;
}
.custom-switch .v-input--selection-controls__ripple {
  display: none !important;
}
</style>
